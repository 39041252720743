import axios from '../../plugins/axios';
export const page_details_module = {
    state: ()=>({
        pending: false,
        data: {
            current_page: 1,
            data: [],
            last_page: null,
            searchedKeyword:'',
        },
        message: null,
        success: null,
        error: null
    }),
    mutations: {
        fetch_page_details_request(state){

            state.pending = true;

        },
        fetch_page_details_success(state, payload){
    
            if(payload.loadData == 'new'){

                state.data.data = payload.pages.data

            }
            if(payload.loadData == 'more'){

                let moreCustomers = payload.pages.data

                moreCustomers.forEach(element => {
                    state.data.data.push(element);
                });

            }

            state.data.current_page = payload.pages.current_page
            state.data.last_page = payload.pages.last_page
            state.pending = false;
            state.success = true;
            state.error = null;
            state.message = payload.message;

        },
        fetch_page_details_failure(state, payload){

            state.data = payload;
            state.pending = false;
            state.success = false;
            state.error = payload.error;
            state.message = payload.message;

        },
        add_page_detail_list(state,payload){

            state.data.data.unshift(payload);

        },
        update_page_detail_list(state,payload){

            state.data.data[payload.index] = payload.updatedPageDetails

        },
        update_page_detail_status(state,payload){

            state.data.data[payload.index].isActive = payload.updatedCustomer.isActive

        },
        delete_page_detail_list(state,payload){

            state.data.data.splice(payload,1);

        },
        update_searched_keyword(state,payload){ 

            state.data.searchedKeyword = payload.keyword

        },
        remove_searched_keyword(state){

            state.data.searchedKeyword = ''

        },
        CLEAR_MODULE(state){

            state.pending = false
            state.message = null
            state.success = null
            state.error = null

            state.data.current_page = 1
            state.data.data = []
            state.data.last_page = null,
            state.data.searchedKeyword= ''

        }
    },
    actions:{
        async fetchStorePageDetailsRequest({commit},{size, page, loadData}){

            commit('fetch_page_details_request');
            try{
                let res = null

                if(this.state.page_details_module.data.searchedKeyword.length > 0){
                    
                    res = await axios.get('/page-details/all',{
                        params:{
                            search: this.state.page_details_module.data.searchedKeyword,
                            offset: size,
                            page: page
                        }
                    });

                }else{

                    res = await axios.get('/page-details/all',{
                        params:{
                            offset: size,
                            page: page
                        }
                    });

                }

                if(res.data.status_code == '1310'){

                    res.data.loadData = loadData

                    commit('fetch_page_details_success', res.data);
                }

            }catch(ex){

                commit('fetch_page_details_failure', {error: ex, message: ex});

            }
            
        }
    },
    getters: {
        pageDetails(state){
            return state.data.data
        },
        keyword(state){
            return state.data.searchedKeyword
        },
        currentPage(state){
            return state.data.current_page
        },
        lastPage(state){
            return state.data.last_page
        },
        isPending(state){
            return state.pending
        },
        message(state){
            return state.message
        },
        error:(state)=>state.error
    }
}