import axios from '../../plugins/axios';
export const brands_module = {
    state: ()=>({
        pending: false,
        data: {
            current_page: 1,
            data: [],
            last_page: null,
            searchedKeyword:'',
        },
        message: null,
        success: null,
        error: null
    }),
    mutations: {
        fetch_brands_request(state){
            state.pending = true;
        },
        fetch_brands_success(state, payload){

            if(payload.loadData == 'new'){
                state.data.data = payload.brands.data
            }
            if(payload.loadData == 'more'){

                payload.brands.data.forEach(element => {
                    state.data.data.push(element)
                });

            }

            state.data.current_page = payload.brands.current_page
            state.data.last_page = payload.brands.last_page
            state.pending = false;
            state.success = true;
            state.error = null;
            state.message = payload.message;

        },
        fetch_brands_failure(state, payload){
            state.data = payload;
            state.pending = false;
            state.success = false;
            state.error = payload.error;
            state.message = payload.message
        },
        add_brands_list(state,payload){
            state.data.data.unshift(payload);
        },
        update_brands_list(state,payload){
            state.data.data[payload.recordIndex] = payload.brand

            // if(payload.category.isActive == 0){
            //     state.data.data.forEach((category)=>{ 
            //         if(category.parent_id && (category.parent_id == payload.category.id)){
            //             category.parent_id = null

            //             category.parents = []
            //         }
            //     })
            // }

        },
        update_brands_status(state,payload){
            state.data.data[payload.index].isActive = payload.updatedCategory.isActive


            if(payload.updatedCategory.isActive == 0){
                state.data.data.forEach((category)=>{ 
                    if(category.parent_id && (category.parent_id == payload.updatedCategory.id)){
                        category.parent_id = null

                        category.parents = []

                    }
                })
            }

        },
        delete_brands_list(state,payload){

            state.data.data.splice(payload,1);

        },
        brands_bulk_action(state,payload){
            
            if(payload.action == 'delete'){

                state.data.data = state.data.data.filter((brand)=>{

                    if(!payload.listIds.includes(brand.id)){
                        
                        return brand

                    }

                });

            }else if(payload.action == 'statusChange'){

                state.data.data.forEach((brand) =>{

                    if(payload.listIds.includes(brand.id)){

                        brand.isActive = payload.status

                    }

                });

            }
            
        },
        update_searched_keyword(state,payload){
            state.data.searchedKeyword = payload.keyword
        },
        remove_searched_keyword(state){
            state.data.searchedKeyword = ''
        },
        CLEAR_MODULE(state){

            state.pending = false
            state.message = null
            state.success = null
            state.error = null

            state.data.current_page = 1
            state.data.data = []
            state.data.last_page = null,
            state.data.searchedKeyword= ''
        }
    },
    actions:{
        async fetchBrandsRequest({commit},{size, page, loadData}){

            commit('fetch_brands_request');
            try{
                let res = null
                if(this.state.brands_module.data.searchedKeyword.length > 0){
    
                    res = await axios.get('/brand/all',{
                        params:{
                            search: this.state.brands_module.data.searchedKeyword,
                            offset: size,
                            page: page
                        }
                    })
                }else{
                    res = await axios.get('/brand/all',{
                        params:{
                            offset: size,
                            page: page
                        }
                    })
                }
    
                if(res.data.status_code == '1390'){
                    res.data.loadData = loadData

                    commit('fetch_brands_success', res.data,)
                }
            }catch(ex){
                commit('fetch_brands_failure', {error: ex, message: ex})
            }
        }
    },
    getters: {
        brands(state){
            return state.data.data
        },
        keyword(state){
            return state.data.searchedKeyword
        },
        isPending(state){
            return state.pending
        },
        currentPage(state){
            return state.data.current_page
        },
        lastPage(state){
            return state.data.last_page
        },
        message(state){
            return state.message
        },
        error:(state)=>state.error
    }
}