import axios from '../../plugins/axios';
export const themes_module = {
    state: ()=>({
        pending: false,
        data: {
            data: []
        },
        message: null,
        success: null,
        error: null
    }),
    mutations: {
        fetch_themes_request(state){
            state.pending = true;
        },
        fetch_themes_success(state, payload){
            
            state.data.data = payload.data.filter(theme => theme.isActive == true);
            state.pending = false;
            state.success = true;
            state.error = null;
            state.message = payload.message;
        },
        fetch_themes_failure(state, payload){
            state.data = payload;
            state.pending = false;
            state.success = false;
            state.error = payload.error;
            state.message = payload.message
        },
        add_vendors_list(state,payload){
            state.data.data.unshift(payload);
        },
        update_vendors_list(state,payload){

            state.data.data[payload.index] = payload.vendor

            // const index = state.data.findIndex(x=>x.id === payload.id)

            // if(index !== -1){
            //     state.data[index] = payload;
            //     console.log(state.data);
            // }
        },
        delete_vendors_list(state,payload){
            state.data.data.splice(payload,1);
        },
        CLEAR_MODULE(state){

            state.pending = false
            state.message = null
            state.success = null
            state.error = null

            state.data.data = []
        }
    },
    actions:{
        async fetchThemesRequest({commit}){
            commit('fetch_themes_request');
            try{
                let res = await axios.get('/getThemes');
                if(res.data.status_code == '1150'){
                    commit('fetch_themes_success', res.data.themes)
                }
            }catch(ex){
                commit('fetch_themes_failure', {error: ex, message: ex})
            }
        },
    },
    getters: {
        themes(state){
            return state.data.data
        },
        isPending(state){
            return state.pending
        },
        message(state){
            return state.message
        },
        error:(state)=>state.error
    }
}