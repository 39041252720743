import axios from '../../plugins/axios';
export const vendor_module = {
    state: ()=>({
        pending: false,
        data: {
            current_page: 1,
            data: [],
            last_page: null,
            searchedKeyword:'',
        },
        message: null,
        success: null,
        error: null
    }),
    mutations: {
        fetch_vendors_request(state){
            state.pending = true;
        },
        fetch_vendors_success(state, payload){
            
            if(payload.loadData == 'new'){
                state.data.data = payload.vendors.data;
            }
            if(payload.loadData == 'more'){
                let moreCustomers = payload.vendors.data

                moreCustomers.forEach(element => {
                    state.data.data.push(element);
                });
            }

            state.data.current_page = payload.vendors.current_page
            state.data.last_page = payload.vendors.last_page
            state.pending = false;
            state.success = true;
            state.error = null;
            state.message = payload.message;

        },
        fetch_vendors_failure(state, payload){
            state.data = payload;
            state.pending = false;
            state.success = false;
            state.error = payload.error;
            state.message = payload.message
        },
        add_vendors_list(state,payload){
            state.data.data.unshift(payload);
        },
        update_vendors_list(state,payload){

            state.data.data[payload.index] = payload.vendor

        },
        delete_vendors_list(state,payload){

            state.data.data.splice(payload,1);

        },
        vendors_bulk_action(state,payload){

            if(payload.action == 'delete'){
            
                state.data.data = state.data.data.filter((vendor)=>{

                    if(!payload.listIds.includes(vendor.id)){

                        return vendor;

                    }

                });

            }

        },
        update_searched_keyword(state,payload){

            state.data.searchedKeyword = payload.keyword

        },
        remove_searched_keyword(state){

            state.data.searchedKeyword = ''

        },
        CLEAR_MODULE(state){

            state.pending = false
            state.message = null
            state.success = null
            state.error = null

            state.data.current_page = 1
            state.data.data = []
            state.data.last_page = null,
            state.data.searchedKeyword= ''
            
        }
    },
    actions:{
        async fetchStoreVendorsRequest({commit},{size, page, loadData}){

            commit('fetch_vendors_request');
            try{

                let res = null
                if(this.state.vendor_module.data.searchedKeyword.length > 0){

                    res = await axios.get('/vendor/all',{
                        params:{
                            search: this.state.vendor_module.data.searchedKeyword,
                            offset: size,
                            page: page
                        }
                    });

                }else{

                    res = await axios.get('/vendor/all',{
                        params:{
                            offset: size,
                            page: page
                        }
                    });

                }
                if(res.data.status_code == '1046'){
                    res.data.loadData = loadData

                    commit('fetch_vendors_success', res.data)
                }

            }catch(ex){
                commit('fetch_vendors_failure', {error: ex, message: ex})
            }
        },
    },
    getters: {
        vendors(state){
            return state.data.data
        },
        isPending(state){
            return state.pending
        },
        currentPage(state){
            return state.data.current_page
        },
        lastPage(state){
            return state.data.last_page
        },
        keyword(state){
            return state.data.searchedKeyword
        },
        message(state){
            return state.message
        },
        error:(state)=>state.error
    }
}