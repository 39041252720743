import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from './plugins/axios'
import AOS from 'aos';
import VueCookies from 'vue-cookies'
import VueComp from '@vue/composition-api'
import VueSelect from 'vue-select'
import Multiselect from 'vue-multiselect'
import VueTelInput from 'vue-tel-input';
import 'vue-tel-input/dist/vue-tel-input.css';
import VueMeta from 'vue-meta';
import VueTour from 'vue-tour';
import VuePapaParse from 'vue-papa-parse'

//Vee-Validate 
import { ValidationProvider } from 'vee-validate/dist/vee-validate.full.esm'
import { ValidationObserver } from 'vee-validate'

Vue.component('ValidationProvider',ValidationProvider)
Vue.component('ValidationObserver',ValidationObserver)
Vue.component('multiselect', Multiselect)

// import {registerables,Chart} from 'chart.js'
// Chart.register(...registerables)
// import Chart from 'chart.js/auto'

//Registering Global Plugins
import './plugins/element.js'
import './plugins/quill-editor.js'
import './plugins/skeleton-loader.js'
import './plugins/ai-element.js'

//Registering Global Css
import './assets/font-awesome-4.7.0/css/font-awesome.min.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap'
import 'aos/dist/aos.css';
import 'vue-select/dist/vue-select.css';
import './assets/css/style.css';
import './assets/css/style_latest.css';
import './assets/css/responsive.css';
import './assets/css/custom_responsive.css';
import 'vue-multiselect/dist/vue-multiselect.min.css';
// import 'vue-tour/dist/vue-tour.css'

require('vue-tour/dist/vue-tour.css')

require("dotenv").config()
//config 
Vue.config.productionTip = false

//prototypes
Vue.prototype.$axios = axios

const globalOptions = {
  mode: 'auto',
};

//global plugins
Vue.use(VueCookies)
Vue.use(VueComp)
Vue.use(VueTelInput,globalOptions)
Vue.use(VueMeta);
Vue.use(VueTour);
Vue.use(VuePapaParse);


//Global component
Vue.component('v-select',VueSelect);

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')

if(process.env.VUE_APP_ENV == 'production'){

  Vue.config.silent = true

}

//handle Refresh
let user = Vue.$cookies.get('meri_web_shop.current_user');
if(user){

  if(store.state.user == null){

    store.dispatch('authLoggedIn', user);

  }
  
}


AOS.init({
  duration: 1200,
})


export {router};