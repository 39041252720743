import axios from '../../plugins/axios';
export const pie_graph_module = {
    state: ()=>({
        pending: false,
        data: {
            years:[],
            data: [],
            filter:'',
        },
        message: null,
        success: null,
        error: null
    }),
    mutations: {
        fetch_pie_graph_request(state){
            state.pending = true;
        },
        fetch_pie_graph_success(state, payload){
            
            state.data.data = payload
            state.data.days = payload.days
            state.pending = false;
            state.success = true;
            state.error = null;
            state.message = payload.message;

        },
        fetch_pie_graph_failure(state, payload){
            state.data.data = payload;
            state.pending = false;
            state.success = false;
            state.error = payload.error;
            state.message = payload.message
        },
        add_expenses_list(state,payload){
            state.data.data.unshift(payload);
        },
        update_expenses_list(state,payload){

            const index = state.data.data.findIndex(x=>x.id === payload.id)
  
            if(index !== -1){
                state.data.data[index] = payload;
            }
        },
        delete_expenses_list(state,payload){
            state.data.data.splice(payload,1);
        },
        CLEAR_MODULE(state){
            state.pending = false
            state.message = null
            state.success = null
            state.error = null

            state.data.years = []
            state.data.data = []
            state.data.filter = ''
        }
    },
    actions:{
        async fetchPieGraphRequest({commit},payload){
            commit('fetch_pie_graph_request');
            try{
                this.state.pie_graph_module.data.filter = payload.filter
                let res = await axios.get(`/getSalesDistribution?filter=${payload.filter}`);
                if(res.data.status_code == '1222'){
                    commit('fetch_pie_graph_success', res.data)
                }
            }catch(ex){
                commit('fetch_pie_graph_failure', {error: ex, message: ex})
            }
        },
    },
    getters: {
        PieGraphData(state){
            return state.data.data
        },
        isPending(state){
            return state.pending
        },
        selectedFilter(state){
            return state.data.filter
        },
        message(state){
            return state.message
        },
        error:(state)=>state.error
    }
}