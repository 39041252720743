import axios from '../../plugins/axios';
export const product_stats = {
    state:()=>({
        pending:false,
        data:{
            data:[],
        },
        message: null,
        success: null,
        error: null
    }),
    mutations:{
        fetch_stats_request(state){
            state.pending = true;
        },
        fetch_stats_success(state, payload){
            
            state.data.data = payload.product_stats;
            state.pending = false;
            state.success = true;
            state.error = null;
            state.message = payload.message;
        },
        fetch_stats_failure(state, payload){
            state.data = payload;
            state.pending = false;
            state.success = false;
            state.error = payload.error;
            state.message = payload.message
        },
        add_vendors_list(state,payload){
            state.data.data.unshift(payload);
        },
        durationChange(state,payload){
            state.data.duration = payload
        },
        
        update_vendors_list(state,payload){

            state.data.data[payload.index] = payload.vendor

            // const index = state.data.findIndex(x=>x.id === payload.id)

            // if(index !== -1){
            //     state.data[index] = payload;
            //     console.log(state.data);
            // }
        },
        delete_vendors_list(state,payload){
            state.data.data.splice(payload,1);
        },
        CLEAR_MODULE(state){

            state.pending = false
            state.message = null
            state.success = null
            state.error = null

            state.data.data = []
        }
    },
    actions:{
        async fetchStatsRequest({commit}){
            commit('fetch_stats_request');
            try{
                let res = await axios.get('/getProductStats');
                if(res.data.status_code == '10093'){
                    commit('fetch_stats_success', res.data)
                }
            }catch(ex){
                commit('fetch_stats_failure', {error: ex, message: ex})
            }
        },
    },
    getters:{
        product_stats(state){
            return state.data.data
        },
        isPending(state){
            return state.pending
        },
        message(state){
            return state.message
        },
        error:(state)=>state.error
    }
}