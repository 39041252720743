import axios from '@/plugins/axios';

export const groups_module = {
    state: ()=>({
        pending: false,
        data: {
            current_page: 1,
            data: [],
            last_page: null,
            searchedKeyword:'',
            allGroups:[],
        },
        message: null,
        success: null,
        error: null
    }),
    mutations: {
        fetch_groups_request(state){

            state.pending = true;

        },
        fetch_groups_success(state, payload){

            if(payload.loadData == 'new'){

                state.data.data = payload.groups.data

            }
            if(payload.loadData == 'more'){

                payload.groups.data.forEach(element => {

                    state.data.data.push(element);

                });

            }

            state.data.current_page = payload.groups.current_page
            state.data.last_page = payload.groups.last_page
            state.pending = false;
            state.success = true;
            state.error = null;
            state.message = payload.message;

        },
        fetch_groups_failure(state, payload){

            state.data = payload;
            state.pending = false;
            state.success = false;
            state.error = payload.error;
            state.message = payload.message

        },
        fetch_all_groups_success(state, payload){
            
            state.data.allGroups = payload.data.groups

        },
        add_groups_list(state,payload){

            state.data.data.unshift(payload);
            
            state.data.allGroups.unshift(payload);

        },
        update_groups_list(state,payload){

            state.data.data[payload.index] = payload.updatedGroup

            state.data.allGroups[payload.index] = payload.updatedGroup
            
        },
        delete_groups_list(state,payload){

            state.data.data.splice(payload,1);

            state.data.allGroups.splice(payload,1);

        },
        groups_bulk_action(state,payload){

            state.data.data = state.data.data.filter((group)=>{

                if(!payload.listIds.includes(group.id)){

                    return group

                }

            });

            state.data.allGroups = state.data.allGroups.filter((group)=>{

                if(!payload.listIds.includes(group.id)){

                    return group

                }

            });

        },
        update_searched_keyword(state,payload){ 

            state.data.searchedKeyword = payload.keyword

        },
        remove_searched_keyword(state){

            state.data.searchedKeyword = ''

        },
        CLEAR_MODULE(state){

            state.pending = false
            state.message = null
            state.success = null
            state.error = null

            state.data.current_page = 1
            state.data.data = []
            state.data.last_page = null
            state.data.searchedKeyword= ''
            state.data.allGroups = []
        }

    },
    actions:{
        async fetchStoreGroupsRequest({commit},{size, page, loadData}){
            
            commit('fetch_groups_request');

            try{
                
                let res = null

                if(this.state.groups_module.data.searchedKeyword.length > 0){

                    res = await axios.get('/crm/group/all',{
                        params:{
                            search: this.state.groups_module.data.searchedKeyword,
                            offset: size,
                            page: page
                        }
                    })

                }else{
                    res = await axios.get('/crm/group/all',{
                        params:{
                            offset: size,
                            page: page,
                        }
                    })
                }

                if(res.data.status_code == '1440'){

                    res.data.loadData = loadData

                    commit('fetch_groups_success', res.data)

                }

            }catch(ex){

                commit('fetch_groups_failure', {error: ex, message: ex})

            }
        },
        async fetchAllGroupsRequest({commit}){

            try{

                let res = await axios.get('crm/group/all');
                if(res.data.status_code == '1440'){

                    commit('fetch_all_groups_success', res)

                }

            }catch(ex){

            }

        },
    },
    getters:{
        groups(state){
            return state.data.data
        },
        allGroups(state){
            return state.data.allGroups
        },
        keyword(state){
            return state.data.searchedKeyword
        },
        isPending(state){
            return state.pending
        },
        currentPage(state){
            return state.data.current_page
        },
        lastPage(state){
            return state.data.last_page
        },
        message(state){
            return state.message
        },
        error:(state)=>state.error
    },
}