import axios from '../../plugins/axios';
export const shipping_module = {
    state: ()=>({
        pending: false,
        data: {
            current_page: 1,
            data: [],
            last_page: null,
            stats:{},
            brands:[],
            searchedKeyword:'',
        },
        message: null,
        success: null,
        error: null
    }),
    mutations: {
        fetch_regions_request(state){
            state.pending = true;
        },
        fetch_regions_success(state, payload){

            if(payload.loadData == 'new'){

                state.data.data = payload.regions.data.map((region) => {
                    region.shipping.rules = JSON.parse(region.shipping.rules);
                    return region;
                });

            }
            if(payload.loadData == 'more'){

                let moreRegions = payload.regions.data.map((region) => {
                    region.shipping.rules = JSON.parse(region.shipping.rules);
                    return region;
                });
    
                moreRegions.forEach(element => {
                    state.data.data.push(element);
                });

            }

            state.data.current_page = payload.regions.current_page
            state.data.last_page = payload.regions.last_page
            state.pending = false;
            state.success = true;
            state.error = null;
            state.message = payload.message;

        },
        fetch_regions_failure(state, payload){
            state.data = payload;
            state.pending = false;
            state.success = false;
            state.error = payload.error;
            state.message = payload.message
        },
        add_regions_list(state,payload){
            state.data.data.unshift(payload);
        },
        update_regions_list(state,payload){

            const index = state.data.data.findIndex(x=>x.id === payload.id)
  
            if(index !== -1){
                state.data.data[index] = payload;
            }
        },
        update_regions_status(state,payload){

            state.data.data[payload.index].isActive = payload.updatedRegion.isActive

        },
        selective_region_update(state,payload){

            if(payload.field == 'name'){

                state.data.data[payload.regionIndex].name = payload.regionName

            }

        },
        delete_regions_list(state,payload){
            
            state.data.data.splice(payload,1);
        },
        brands_bulk_action(state,payload){

            if(payload.action == 'delete'){

                state.data.data = state.data.data.filter((region)=>{

                    if(!payload.listIds.includes(region.id)){
                        
                        return region

                    }

                });

            }else if(payload.action == 'statusChange'){
                
                state.data.data.forEach((region) =>{

                    if(payload.listIds.includes(region.id)){

                        region.isActive = payload.status

                    }

                });

            }

        },
        update_searched_keyword(state,payload){ 
            state.data.searchedKeyword = payload.keyword
        },
        remove_searched_keyword(state){
            state.data.searchedKeyword = ''
        },
        CLEAR_MODULE(state){
            state.pending = false
            state.message = null
            state.success = null
            state.error = null

            state.data.current_page = 1
            state.data.data = []
            state.data.last_page = null
            state.data.stats = {}
            state.data.brands = []
        }
    },
    actions:{

        async fetchStoreRegionsRequest({commit},{size, page, loadData}){

            commit('fetch_regions_request');
            try{

                let res = null;

                if(this.state.shipping_module.data.searchedKeyword.length > 0){
                    
                    res = await axios.get('/getRegions',{
                        params:{
                            search: this.state.shipping_module.data.searchedKeyword,
                            offset: size,
                            page: page,
                        }
                    })

                }else{
                    res = await axios.get('/getRegions',{
                        params:{
                            offset: size,
                            page: page,
                        }
                    })
                }
                if(res.data.status_code == '1011'){

                    res.data.loadData = loadData

                    commit('fetch_regions_success', res.data)
                }

            }catch(ex){
                commit('fetch_regions_failure', {error: ex, message: ex})
            }
        }
    },
    getters: {
        regions(state){
            return state.data.data
        },
        keyword(state){
            return state.data.searchedKeyword
        },
        currentPage(state){
            return state.data.current_page
        },
        lastPage(state){
            return state.data.last_page
        },
        isPending(state){
            return state.pending
        },
        message(state){
            return state.message
        },
        error:(state)=>state.error
    }
}