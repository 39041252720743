import store from '..';
import axios from '../../plugins/axios';

export const navigations_module = {
    state:()=>({
        pending: false,
        header:[],
        footer:[],
        message: null,
        success: null,
        error: null
    }),
    mutations:{
        fetch_navigation_request(state){
            state.pending = true;
        },
        fetch_navigation_success(state, payload){
            
            if(payload.navigation == 'main'){
                state.header = payload.data
            }else if(payload.navigation == 'footer'){
                state.footer = payload.data
            }

            state.pending = false;
            state.success = true;
            state.error = null;
            state.message = payload.message;
        },
        fetch_navigation_failure(state, payload){
            state.pending = false;
            state.success = false;
            state.error = payload.error;
            state.message = payload.message
        },
        update_navigation(state,payload){
            if(payload.navigationName == 'main'){
                state.header = payload.headerNav
            }else if (payload.navigationName == 'footer'){
                state.footer = payload.footerNav
            }
        },
        CLEAR_MODULE(state){
            state.pending = false
            state.message = null
            state.success = null
            state.error = null

            state.header = []
            state.footer = []
        },
    },
    actions:{
        async fetchNavigationRequest({commit},{navigationName}){
            commit("fetch_navigation_request");
            try{
                let res = await axios.get(`getNavigation`,{
                    params:{
                      name: navigationName
                    }
                });
                if(res.data.status_code == "1050"){
                    let payload = {
                        data: JSON.parse(res.data.navigation.menu),
                        navigation: navigationName
                    }

                    commit('fetch_navigation_success', payload)
                }
            }catch(ex){
                commit('fetch_navigation_failure', {error: ex, message: ex})
            }
        }
    },
    getters:{
        headerNavigation(state){
            return state.header
        },
        footerNavigation(state){
            return state.footer
        },
        isPending(state){
            return state.pending
        },
        message(state){
            return state.message
        },
        error:(state)=>state.error
    }
}