import axios from 'axios'
import store from '../store/index'
import {router} from '../main'
import Vue from 'vue'

const request = axios.create({
  baseURL: process.env.VUE_APP_BASE_URL
})
// console.log(process.env.VUE_APP_BASE_URL);
// const request = axios.create();
request.interceptors.request.use(function (config) {

  console.log('request intercepter called');

  if(store.state.loggedIn){

    config.headers["Authorization"] = `Bearer ${store.state.user.token}`;
    config.headers["X-Store-Id"] = `${store.state.user.store_id}`;
    return config;

  }

  return config;

}, function (error) {

  return Promise.reject(error);
  
});


request.interceptors.response.use(function (response) {
  // Any status code that lie within the range of 2xx cause this function to trigger
  // Do something with response data
  return response;

}, function (error) {

  // Do something with request error
  if(error.response && error.response.status === 401){

    let user = Vue.$cookies.get('meri_web_shop.current_user');

    if(user){

      Vue.$cookies.remove('meri_web_shop.current_user');

    }

    store.dispatch('authLoggedOut');

    router.push('/login');

   }

  throw error

});


export default request;

