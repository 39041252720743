import axios from '../../plugins/axios';
export const subscription_module = {
    state:()=>({
        pending:false,
        data:{
            data:[],
            packages:[],
        },
        message: null,
        success: null,
        error: null
    }),
    mutations:{
        fetch_subscription_request(state){
            state.pending = true;
        },
        fetch_subscription_success(state, payload){
            
            state.data.data = payload;
            state.pending = false;
            state.success = true;
            state.error = null;
            state.message = payload.message;

        },
        fetch_subscription_failure(state, payload){

            state.data.data = payload;
            state.pending = false;
            state.success = false;
            state.error = payload.error;
            state.message = payload.message

        },
        fetch_subscriptionPackages_success(state,payload){

            state.data.packages = payload;
            state.pending = false;
            state.success = false;
            state.error = payload.error;
            state.message = payload.message

        },
        fetch_subscriptionPackages_failure(state, payload){

            state.data.packages = payload;
            state.pending = false;
            state.success = false;
            state.error = payload.error;
            state.message = payload.message

        },
        CLEAR_MODULE(state){

            state.pending = false
            state.message = null
            state.success = null
            state.error = null

            state.data.data = []
        }
    },
    actions:{
        async fetchSubscriptionRequest({commit}){
            commit('fetch_subscription_request');
            try{
                let res = await axios.get('/getStoreSubscription');
                if(res.data.status_code == '1170'){
                    commit('fetch_subscription_success', res.data.subscription)
                }
            }catch(ex){
                commit('fetch_subscription_failure', {error: ex, message: ex})
            }
        },
        async fetchSubscriptionPackagesRequest({commit}){
            commit('fetch_subscription_request');
            try{
                let res = await axios.get('/getPackages?active=1');
                if(res.data.status_code == '1210'){
                    commit('fetch_subscriptionPackages_success', res.data.packages)
                }
            }catch(ex){
                commit('fetch_subscriptionPackages_failure', {error: ex, message: ex})
            }
        }
    },
    getters:{
        subscription(state){
            return state.data.data
        },
        packages(state){
            return state.data.packages
        },
        isPending(state){
            return state.pending
        },
        message(state){
            return state.message
        },
        error:(state)=>state.error
    }
}