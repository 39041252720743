import store from '..';
import axios from '../../plugins/axios';

export const form_summary_module = {
    state:()=>({
        pending: false,
        data:[],
        message: null,
        success: null,
        error: null
    }),
    mutations:{
        fetch_form_summary_request(state){
            state.pending = true;
        },
        fetch_form_summary_success(state, payload){
            
            state.data = payload
            state.pending = false;
            state.success = true;
            state.error = null;
            state.message = payload.message;
        },
        fetch_form_summary_failure(state, payload){
            state.pending = false;
            state.success = false;
            state.error = payload.error;
            state.message = payload.message
        },
        update_form_summary(state,payload){
            state.data = payload
        },
        CLEAR_MODULE(state){
            state.pending = false
            state.message = null
            state.success = null
            state.error = null

            state.header = []
            state.footer = []
        },
    },
    actions:{
        async fetchFormSummaryRequest({commit}){
            commit("fetch_form_summary_request");
            try{
                let res = await axios.get('getFormSubmissions');
                if(res.data.status_code == "1111"){
                    commit('fetch_form_summary_success',res.data.forms)
                }
            }catch(ex){
                commit('fetch_form_summary_failure', {error: ex, message: ex})
            }
        }
    },
    getters:{
        formSummaries(state){
            return state.data
        },
        isPending(state){
            return state.pending
        },
        message(state){
            return state.message
        },
        error:(state)=>state.error
    }
}