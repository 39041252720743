import axios from '../../plugins/axios';
export const seo_config = {
    state: ()=>({
        pending: false,
        data: null,
        message: null,
        success: null,
        error: null
    }),
    mutations: {
        fetch_seo_config_request(state){
            state.pending = true;
        },
        fetch_seo_config_success(state, payload){

            state.data = payload;
            state.pending = false;
            state.success = true;
            state.error = null;
            state.message = payload.message;
        },
        fetch_seo_config_failure(state, payload){
            state.data = payload;
            state.pending = false;
            state.success = false;
            state.error = payload.error;
            state.message = payload.message
        },
        CLEAR_MODULE(state){
            state.pending = false
            state.message = null
            state.success = null
            state.error = null

            state.data = null
        }
    },
    actions:{
        async fetch_seo_config_Request({commit}){
            commit('fetch_seo_config_request');
            try{
                let res = await axios.get('/getSeoConfigurations');
                if(res.data.status_code == '1029'){
                    commit('fetch_seo_config_success', res.data.seo_config)
                }
            }catch(ex){
                commit('fetch_seo_config_failure', {error: ex, message: ex})
            }
        }
    },
    getters: {
        data(state){
            return state.data
        },
        isPending(state){
            return state.pending
        },
        message(state){
            return state.message
        },
        error:(state)=>state.error
    }
}