import axios from '../../plugins/axios';
export const orders_module = {
    state: ()=>({
        pending: false,
        data: {
            current_page: 1,
            data: [],
            last_page: null,
            searchedKeyword:'',
        },
        message: null,
        success: null,
        error: null
    }),
    mutations: {
        fetch_orders_request(state){

            state.pending = true;

        },
        fetch_orders_success(state, payload){

            if(payload.loadData == 'new'){

                state.data.data = payload.data.map(order=>{

                    order.items = order.items.map(item=>{

                        item.product = JSON.parse(item.product);

                        return item.product;

                    });

                    return order;

                });

            }
            if(payload.loadData == 'more'){

                let moreOrders = payload.data.map(order=>{

                    order.items = order.items.map(item=>{

                        item.product = JSON.parse(item.product);

                        return item.product;

                    });
                    return order;

                });

                moreOrders.forEach(element => {

                    state.data.data.push(element);

                });

            }

            state.data.current_page = payload.current_page
            state.data.last_page = payload.last_page
            state.pending = false;
            state.success = true;
            state.error = null;
            state.message = payload.message;

        },
        fetch_orders_failure(state, payload){

            state.data.data = payload;
            state.pending = false;
            state.success = false;
            state.error = payload.error;
            state.message = payload.message;

        },
        add_orders_list(state,payload){
         
            let jsonItems = []

            payload.items.forEach((item) =>{
                let obj = JSON.parse(item.product)

                jsonItems.push(obj)
            });

            payload.items = jsonItems

            state.data.data.unshift(payload);

        },
        update_orders_list(state,payload){

            state.data.data[payload.i] = payload.updatedOrder;

        },
        update_OrderNote(state,payload){

            const index = state.data.data[payload.orderIndex].notes.findIndex(x=>x.id === payload.updatedOrderNote.id);

            state.data.data[payload.orderIndex].notes[index].note = payload.updatedOrderNote.note;

        },
        delete_orders_list(state,payload){

            state.data.data[payload].status = 'Deleted'

        },
        delete_ordersNote(state,payload){

            state.data.data[payload.orderIndex].notes.splice(payload.noteIndex,1);

        },
        orders_bulk_action(state,payload){

            if(payload.action == 'statusChange'){

                state.data.data = state.data.data.filter((order)=>{

                    let activityText = "Order status was changed from " + order.status + " to " + payload.status +
                    ' By ' + this.state.user.first_name + " " + this.state.user.last_name

                    if(payload.listIds.includes(order.id) && order.previewable != 0){

                        if((order.status != 'Delivered' && order.status != 'Cancelled' && order.status != 'Deleted')){
                            
                            order.status = payload.status

                            let orderActivity = {
                                activity: activityText,
                                created_at: new Date()
                            }

                            order.activities.push(orderActivity)

                            return order

                        }else{

                            return order

                        }
                    }else{

                        return order

                    }
                });

            }else if(payload.action == 'delete'){

                state.data.data = state.data.data.filter((order)=>{

                    let activityText = "Order status was changed from " + order.status + " to " + 'Deleted' +
                    ' By ' + this.state.user.first_name + " " + this.state.user.last_name

                    if(payload.listIds.includes(order.id) & order.previewable != 0){

                        if((order.status != 'Deleted')){

                            order.status = 'Deleted'

                            let orderActivity = {
                                activity: activityText,
                                created_at: new Date()
                            }

                            order.activities.push(orderActivity)

                            return order

                        }else{

                            return order

                        }

                    }else{

                        return order

                    }

                });

            }

        },
        update_searched_keyword(state,payload){

            state.data.searchedKeyword = payload.keyword;

        },
        remove_searched_keyword(state){

            state.data.searchedKeyword = ''

        },
        CLEAR_MODULE(state){

            state.pending = false
            state.message = null
            state.success = null
            state.error = null

            state.data.current_page = 1
            state.data.data = []
            state.data.last_page = null,
            state.data.searchedKeyword= ''
        }
    },
    actions:{
        async fetchStoreOrdersRequest({commit},{size, page, loadData}){

            commit('fetch_orders_request');
            try{
                let res = null

                if(this.state.orders_module.data.searchedKeyword.length > 0){

                    res = await axios.get('/getOrders',{
                        params:{
                            search: this.state.orders_module.data.searchedKeyword,
                            offset: size,
                            page: page,
                        }
                    })

                }else{

                    res = await axios.get('/getOrders',{
                        params:{
                            offset: size,
                            page: page
                        }
                    })

                }

                if(res.data.status_code == '1022'){

                    res.data.orders.loadData = loadData

                    commit('fetch_orders_success', res.data.orders)

                }

            }catch(ex){

                commit('fetch_orders_failure', {error: ex, message: ex})

            }

        }
    },
    getters: {
        storeOrders(state){
            return state.data.data
        },
        isPending(state){
            return state.pending
        },
        currentPage(state){
            return state.data.current_page
        },
        lastPage(state){
            return state.data.last_page
        },
        searchedKeyword(state){
            return state.data.searchedKeyword
        },
        message(state){
            return state.message
        },
        error:(state)=>state.error
    }
}