import axios from '../../plugins/axios';
export const dashboard_stats = {
    state:()=>({
        pending:false,
        data:{
            data:null,
            duration:'today',
            from:'',
            to:'',
        },
        message: null,
        success: null,
        error: null
    }),
    mutations:{
        fetch_stats_request(state){
            state.pending = true;
        },
        fetch_stats_success(state, payload){
            
            state.data.data = payload.stats;
            state.data.duration = payload.duration
            state.pending = false;
            state.success = true;
            state.error = null;
            state.message = payload.message;

        },
        fetch_stats_failure(state, payload){
            state.data.data = payload;
            state.pending = false;
            state.success = false;
            state.error = payload.error;
            state.message = payload.message
        },
        add_vendors_list(state,payload){
            state.data.data.unshift(payload);
        },
        durationChange(state,payload){
            state.data.duration = payload

            if(payload != 'range'){
                state.data.from = ''
                state.data.to = ''
            }

        },
        rangeDuration(state,payload){
            state.data.duration = payload.filter
            state.data.from = payload.from
            state.data.to = payload.to
        },
        update_vendors_list(state,payload){

            state.data.data[payload.index] = payload.vendor

            // const index = state.data.findIndex(x=>x.id === payload.id)

            // if(index !== -1){
            //     state.data[index] = payload;
            //     console.log(state.data);
            // }
        },
        delete_vendors_list(state,payload){
            state.data.data.splice(payload,1);
        },
        CLEAR_MODULE(state){
            state.pending = false
            state.message = null
            state.success = null
            state.error = null

            state.data.current_page = 1
            state.data.data = null
            state.data.duration = 'today',
            state.data.from = ''
            state.data.to = ''
        }
    },
    actions:{
        async fetchStatsRequest({commit},payload){
            commit('fetch_stats_request');
            try{
                let res = null
                if(payload.filter == "range"){
                    let filter = payload.filter
                    let from = payload.from
                    let to = payload.to

                    res = await axios.get(`/getStats?filter=${filter}&from=${from}&to=${to}`);
                    if(res.data.status_code == '1200'){
                        commit('fetch_stats_success', res.data)
                    }
                }else{
                    res = await axios.get(`/getStats?filter=${payload}`);
                    if(res.data.status_code == '1200'){
                        commit('fetch_stats_success', res.data)
                    }
                }

                // if(res.data.status_code == '1200'){
                //     commit('fetch_stats_success', res.data)
                // }
            }catch(ex){
                commit('fetch_stats_failure', {error: ex, message: ex})
            }
        },
    },
    getters:{
        stats(state){
            return state.data.data
        },
        isPending(state){
            return state.pending
        },
        duration(state){
            return state.data.duration
        },
        from(state){
            return state.data.from
        },
        to(state){
            return state.data.to
        },
        message(state){
            return state.message
        },
        error:(state)=>state.error
    }
}