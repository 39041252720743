import axios from '../../plugins/axios';
export const bills_module = {
    state: ()=>({
        pending: false,
        data: {
            current_page: 1,
            data: [],
            last_page: null,
            searchedKeyword:'',
        },
        message: null,
        success: null,
        error: null
    }),
    mutations: {
        fetch_bills_request(state){
            state.pending = true;
        },
        fetch_bills_success(state, payload){

            if(payload.loadData == 'new'){
                state.data.data = payload.bill.data
            }
            if(payload.loadData == 'more'){
                let moreBills = payload.bill.data

                moreBills.forEach(element => {
                    state.data.data.push(element);
                });
            }

            state.data.current_page = payload.bill.current_page
            state.data.last_page = payload.bill.last_page
            state.pending = false;
            state.success = true;
            state.error = null;
            state.message = payload.message;

        },
        fetch_bills_failure(state, payload){
            state.data = payload;
            state.pending = false;
            state.success = false;
            state.error = payload.error;
            state.message = payload.message
        },
        add_bills_list(state,payload){
            state.data.data.unshift(payload);
        },
        update_bills_list(state,payload){

            const index = state.data.data.findIndex(x=>x.id === payload.id)
  
            if(index !== -1){
                state.data.data[index] = payload;
            }
        },
        delete_bills_list(state,payload){
            state.data.data.splice(payload,1);
        },
        bills_bulk_action(state,payload){

            if(payload.action == 'delete'){

                state.data.data = state.data.data.filter((bill)=>{

                    if(!payload.listIds.includes(bill.id)){

                        return bill;

                    }

                });

            }

        },
        update_searched_keyword(state,payload){
            state.data.searchedKeyword = payload.keyword
        },
        remove_searched_keyword(state){
            state.data.searchedKeyword = ''
        },
        CLEAR_MODULE(state){

            state.pending = false
            state.message = null
            state.success = null
            state.error = null

            state.data.current_page = 1
            state.data.data = []
            state.data.last_page = null,
            state.data.searchedKeyword= ''

        }
    },
    actions:{
        async fetchStoreBillsRequest({commit},{size, page, loadData}){

            commit('fetch_bills_request');
            try{

                let res = null
                if(this.state.bills_module.data.searchedKeyword.length > 0){
                    
                    res = await axios.get('/getBills',{
                        params:{
                            search: this.state.bills_module.data.searchedKeyword,
                            offset:size,
                            page:page
                        }
                    })

                }else{

                    res = await axios.get('/getBills',{
                        params:{
                            offset:size,
                            page:page
                        }
                    })

                }
                if(res.data.status_code == '1056'){
                    res.data.loadData = loadData

                    commit('fetch_bills_success', res.data)
                }

            }catch(ex){
                commit('fetch_bills_failure', {error: ex, message: ex})
            }
        }
    },
    getters: {
        bills(state){
            return state.data.data
        },
        isPending(state){
            return state.pending
        },
        currentPage(state){
            return state.data.current_page
        },
        lastPage(state){
            return state.data.last_page
        },
        keyword(state){
            return state.data.searchedKeyword
        },
        message(state){
            return state.message
        },
        error:(state)=>state.error
    }
}