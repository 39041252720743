import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store/index'
import Home from '../views/Home.vue'
import Element from 'element-ui'

Vue.use(VueRouter)

const routes = [
  // {
  //   path: '/',
  //   name: 'Home',
  //   component: PublicLayout,
  //   children:[
  //     {
  //       path:'',
  //       component: Home
  //     },
  //     {
  //       path: '/about',
  //       component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  //     },
  //     {
  //       path:'/blogs',
  //       name: 'blogs',
  //       component: () => import('../views/Blog.vue')

  //     },
  //     {
  //       path: '/contact-us',
  //       name: "contact_us",
  //       component: () => import('../views/ContactUs.vue')
  //     }
  //   ]
  // },
  {
    path: '/login',
    component: () => import('../layouts/LoginLayout.vue'),
    children: [
      {
        path: "/onboarding/ai",
        name: "onboarding-ai",
        component: () => import('../views/ai/index.vue')
      },
      {
        path: '/onboarding',
        name: 'onboarding',
        component:()=> import('../views/onboarding/index.vue')
      },
      {
        path: '/login',
        name:'login',
        component: () => import('../views/login/SignIn.vue')
      },
      {
        path: '/forgotPassword',
        name:'Forgot Password',
        component: () => import('../views/forgotPassword/index.vue')
      },
      {
        path: '/forgotPassword/reset',
        name:'Reset Password',
        component: () => import('../views/forgotPassword/reset.vue')
      },
      {
        path: '/register',
        name: 'register',
        component: () => import('../views/login/SignUp.vue')
      },
      {
        path: '/email-verification',
        name:'Email Verification',
        component: () => import('../views/login/emailVerification.vue')
      },
      {
        path: '/unsubscribe',
        name:'Unsubscribe',
        component: () => import('../views/login/unsubscribe/index.vue')
      },
    ]
  },
  {
    path: '/',
    component: () => import('../layouts/DashboardLayout.vue'),
    meta: {
      requiresAuth: true
    },
    children: [
      //dashboard route start
      {
        path: '',
        name:'dashboard',
        component: () => import('../views/dashboard/index.vue')
      },
      //dashboard route end

      //navigation routes start
      {
        path: 'navigation/header-navigation',
        name: 'header Navigation',
        component: () => import('../views/dashboard/navigation/header-navigation.vue'),
      },
      {
        path: 'navigation/footer-navigation',
        name: 'Footer Navigation',
        component: () => import('../views/dashboard/navigation/footer-navigation.vue'),
      },
      //navigation routes end

      //ecommerce routes start
      {
        path: 'order',
        name: 'order',
        component: () => import('../views/dashboard/order.vue'),
        beforeEnter(to,from,next){

          let isFound = false

          let user = Vue.$cookies.get('meri_web_shop.current_user');

          user.roles.forEach(role => {
            if(role == "manage orders"){
              isFound = true
            }
          });

          if(isFound){

            next();

          }else{

            next(from.fullPath);
            
            Element.Message({
              type: 'error',
              showClose: true,
              message: "You don't have permission to access this module.",
            });

          }

        }

      },
      {
        path: 'product',
        name: 'product',
        component: () => import('../views/dashboard/product.vue'),
        beforeEnter(to,from,next){

          let isFound = false;

          let user = Vue.$cookies.get('meri_web_shop.current_user');

          user.roles.forEach(role => {
            if(role == "manage products"){
              isFound = true
            }
          });

          if(isFound){
            next();
          }else{
            next(from.fullPath);
            
            Element.Message({
              type: 'error',
              showClose: true,
              message: "You don't have permission to access this module.",
            });
          }

        }

      },
      {
        path: 'category',
        name: 'category',
        component: () => import('../views/dashboard/category.vue'),
        beforeEnter(to,from,next){

          let isFound = false;

          let user = Vue.$cookies.get('meri_web_shop.current_user');

          user.roles.forEach(role => {
            if(role == "manage products"){
              isFound = true
            }
          });

          if(isFound){

            next();

          }else{

            next(from.fullPath);
            
            Element.Message({
              type: 'error',
              showClose: true,
              message: "You don't have permission to access this module.",
            });

          }

        }

      },
      {
        path: 'brand',
        name: 'brand',
        component: () => import('../views/dashboard/brands/index.vue'),
        beforeEnter(to,from,next){

          let isFound = false;

          let user = Vue.$cookies.get('meri_web_shop.current_user');

          user.roles.forEach(role => {
            if(role == "manage products"){
              isFound = true
            }
          });

          if(isFound){

            next();

          }else{

            next(from.fullPath);
            
            Element.Message({
              type: 'error',
              showClose: true,
              message: "You don't have permission to access this module.",
            });

          }

        }

      },
      {
        path: 'customer',
        name: 'customer',
        component: () => import('../views/dashboard/customer/index.vue')
      },
      {
        path: 'payment',
        name: 'payment',
        component: () => import('../views/dashboard/payment.vue')
      },
      {
        path: 'shipping',
        name: 'Shipping',
        component: () => import('../views/dashboard/shipping/index.vue')
      },
      {
        path: 'shipping/add',
        name: 'addShipping',
        component: () => import('../views/dashboard/shipping/addShipping.vue')
      },
      {
        path: 'shipping/edit/:id',
        name: 'editShipping',
        component: () => import('../views/dashboard/shipping/editShipping.vue'),
        props: true
      },
      //ecommerce routes end

      //promo & marketing routes start
      {
        path: 'marketing/coupon',
        name: 'Coupons',
        component: () => import('../views/dashboard/marketing/coupons/index.vue'),
      },
      {
        path: 'marketing/pixel-management',
        name: 'Pixel Management',
        component: () => import('../views/dashboard/marketing/pixel-management/index.vue'),
      },
      {
        path: 'marketing/facebook-shop',
        name: 'Facebook Shop',
        component: () => import('../views/dashboard/marketing/facebook-shop/index.vue'),
      },
      {
        path: 'marketing/google-shop',
        name: 'Google Shop',
        component: () => import('../views/dashboard/marketing/google-shop/index.vue'),
      },
      {
        path: 'marketing/social-media',
        name: 'Social Media',
        component: () => import('../views/dashboard/marketing/social-media/index.vue'),
      },
      {
        path: 'marketing/dropshipping',
        name: 'Dropshipping',
        component: () => import('../views/dashboard/marketing/dropshipping/index.vue'),
      },
      //promo & marketing routes end

      //seo routes start
      {
        path: 'seo',
        name: 'seo',
        component: () => import('../views/dashboard/seo/index.vue')
      },
      {
        path: 'seo/redirect',
        name: 'redirects',
        component: () => import('../views/dashboard/seo/redirects/index.vue')
      },
      {
        path: 'seo/robot',
        name: 'robots',
        component: () => import('../views/dashboard/seo/robots/index.vue')
      },
      {
        path: 'seo/page-detail',
        name: 'Page Details',
        component: () => import('../views/dashboard/seo/page-details/index.vue')
      },
      {
        path: 'seo/no-index-url',
        name: 'No Index Urls',
        component: () => import('../views/dashboard/seo/no-index-urls/index.vue')
      },
      {
        path: 'seo/search-appearance',
        name: 'Search Appearance',
        component: () => import('../views/dashboard/seo/search_appearance/index.vue')
      },
      //seo routes end

      //content management routes start
      {
        path: 'content-management/blog-category',
        name: 'Blog Category',
        component: () => import('../views/dashboard/contentManagement/blog-categories/index.vue'),
        beforeEnter(to,from,next){

          let isFound = false;

          let user = Vue.$cookies.get('meri_web_shop.current_user');

          user.roles.forEach(role => {
            if(role == "manage content"){
              isFound = true
            }
          });

          if(isFound){

              next();

          }else{

            next(from.fullPath);
            
            Element.Message({
              type: 'error',
              showClose: true,
              message: "You don't have permission to access this module.",
            });

          }

        }

      },
      {
        path: 'content-management/blog',
        name: 'Blogs',
        component: () => import('../views/dashboard/contentManagement/blogs/index.vue'),
        beforeEnter(to,from,next){

          let isFound = false;

          let user = Vue.$cookies.get('meri_web_shop.current_user');

          user.roles.forEach(role => {
            if(role == "manage content"){
              isFound = true
            }
          });

          if(isFound){

            next();

          }else{

            next(from.fullPath);
            
            Element.Message({
              type: 'error',
              showClose: true,
              message: "You don't have permission to access this module.",
            });

          }

        }

      },
      {
        path: 'content-management/gallery',
        name: 'Gallery',
        component: () => import('../views/dashboard/contentManagement/gallery/index.vue'),
        beforeEnter(to,from,next){

          let isFound = false;

          let user = Vue.$cookies.get('meri_web_shop.current_user');

          user.roles.forEach(role => {
            if(role == "manage content"){
              isFound = true
            }
          });

          if(isFound){

            next();

          }else{

            next(from.fullPath);
            
            Element.Message({
              type: 'error',
              showClose: true,
              message: "You don't have permission to access this module.",
            });

          }

        }

      },
      {
        path: 'content-management/faq',
        name: 'FAQs',
        component: () => import('../views/dashboard/contentManagement/faqs/index.vue'),
        beforeEnter(to,from,next){

          let isFound = false;

          let user = Vue.$cookies.get('meri_web_shop.current_user');

          user.roles.forEach(role => {
            if(role == "manage content"){
              isFound = true
            }
          });

          if(isFound){

            next();

          }else{

            next(from.fullPath);
            
            Element.Message({
              type: 'error',
              showClose: true,
              message: "You don't have permission to access this module.",
            });

          }

        }

      },
      //content management routes end

      //settings routes start
      {
        path: 'settings/store-settings',
        name: 'Store Settings',
        component: () => import('../views/settings/storeSettings.vue'),
        beforeEnter(to,from,next){

          let isFound = false;

          let user = Vue.$cookies.get('meri_web_shop.current_user');

          user.roles.forEach(role => {
            if(role == "manage settings"){
              isFound = true
            }
          });

          if(isFound){

            next();

          }else{

            next(from.fullPath);
            
            Element.Message({
              type: 'error',
              showClose: true,
              message: "You don't have permission to access this module.",
            });

          }

        }

      },
      {
        path: 'settings/domain-setup',
        name: 'Domains',
        component: () => import('../views/settings/domains.vue'),
        beforeEnter(to,from,next){

          let isFound = false;

          let user = Vue.$cookies.get('meri_web_shop.current_user');

          user.roles.forEach(role => {
            if(role == "manage settings"){
              isFound = true
            }
          });

          if(isFound){

            next();

          }else{

            next(from.fullPath);
            
            Element.Message({
              type: 'error',
              showClose: true,
              message: "You don't have permission to access this module.",
            });
          }

        }

      },
      {
        path: 'subscription',
        name: 'Subscription',
        component: () => import('../views/subscription/index.vue'),
        beforeEnter(to,from,next){

          let isFound = false;

          let user = Vue.$cookies.get('meri_web_shop.current_user');

          user.roles.forEach(role => {
            if(role == "manage settings"){
              isFound = true
            }
          });

          if(isFound){

            next();

          }else{

            next(from.fullPath);
            
            Element.Message({
              type: 'error',
              showClose: true,
              message: "You don't have permission to access this module.",
            });

          }

        }

      },
      {
        path: 'settings/privacy-policy',
        name: 'Privacy Policy',
        component: () => import('../views/settings/privacyPolicy.vue'),
        beforeEnter(to,from,next){

          let isFound = false;

          let user = Vue.$cookies.get('meri_web_shop.current_user');

          user.roles.forEach(role => {
            if(role == "manage settings"){
              isFound = true
            }
          });

          if(isFound){

            next();

          }else{

            next(from.fullPath);
            
            Element.Message({
              type: 'error',
              showClose: true,
              message: "You don't have permission to access this module.",
            });

          }

        }

      },
      {
        path: 'settings/terms-and-conditions',
        name: 'Terms and Conditions',
        component: () => import('../views/settings/termsAndConditions.vue'),
        beforeEnter(to,from,next){

          let isFound = false;

          let user = Vue.$cookies.get('meri_web_shop.current_user');

          user.roles.forEach(role => {
            if(role == "manage settings"){
              isFound = true
            }
          });

          if(isFound){

            next();

          }else{

            next(from.fullPath);
            
            Element.Message({
              type: 'error',
              showClose: true,
              message: "You don't have permission to access this module.",
            });

          }

        }

      },
      //settings routes end

      //theme management routes start
      {
        path: 'theme-management',
        name: 'Theme Management',
        component: () => import('../views/dashboard/themeManagement/index.vue'),
        beforeEnter(to,from,next){

          let isFound = false;

          let user = Vue.$cookies.get('meri_web_shop.current_user');

          user.roles.forEach(role => {
            if(role == "manage settings"){
              isFound = true
            }
          });

          if(isFound){

            next();

          }else{

            next(from.fullPath);
            
            Element.Message({
              type: 'error',
              showClose: true,
              message: "You don't have permission to access this module.",
            });

          }

        }

      },
      {
        path: 'theme-management/theme-customization',
        name: 'Theme Customization',
        component: () => import('../views/dashboard/contentManagement/content-blocks/index.vue'),
        beforeEnter(to,from,next){

          let isFound = false;

          let user = Vue.$cookies.get('meri_web_shop.current_user');

          user.roles.forEach(role => {
            if(role == "manage content"){
              isFound = true
            }
          });

          if(isFound){

            next();

          }else{

            next(from.fullPath);
            
            Element.Message({
              type: 'error',
              showClose: true,
              message: "You don't have permission to access this module.",
            });

          }

        }

      },
      {
        path: 'theme-management/theme-banner',
        name: 'Home Banner',
        component: () => import('../views/dashboard/home-banner/home-banner.vue'),
        beforeEnter(to,from,next){

          let isFound = false;

          let user = Vue.$cookies.get('meri_web_shop.current_user');

          user.roles.forEach(role => {
            if(role == "manage content"){
              isFound = true
            }
          });

          if(isFound){
          
            next();

          }else{

            next(from.fullPath);
            
            Element.Message({
              type: 'error',
              showClose: true,
              message: "You don't have permission to access this module.",
            });

          }

        }

      },
      //theme management routes end

      //expenses/purchases routes start
      {
        path: 'expenses-purchases/account',
        name: 'Accounts',
        component: () => import('../views/dashboard/expensesPurchases/accounts/index.vue'),
        beforeEnter(to,from,next){

          let isFound = false;

          let user = Vue.$cookies.get('meri_web_shop.current_user');

          user.roles.forEach(role => {
            if(role == "manage expense"){
              isFound = true
            }
          });

          if(isFound){

            next();

          }else{

            next(from.fullPath);
            
            Element.Message({
              type: 'error',
              showClose: true,
              message: "You don't have permission to access this module.",
            });

          }

        }
      },
      {
        path: 'expenses-purchases/expense',
        name: 'Expenses',
        component: () => import('../views/dashboard/expensesPurchases/expenses/index.vue'),
        beforeEnter(to,from,next){

          let isFound = false;

          let user = Vue.$cookies.get('meri_web_shop.current_user');

          user.roles.forEach(role => {
            if(role == "manage expense"){
              isFound = true
            }
          });

          if(isFound){

            next();

          }else{

            next(from.fullPath);
            
            Element.Message({
              type: 'error',
              showClose: true,
              message: "You don't have permission to access this module.",
            });

          }

        }

      },
      {
        path: 'expenses-purchases/vendor',
        name: 'Vendors',
        component: () => import('../views/dashboard/expensesPurchases/vendors/index.vue'),
        beforeEnter(to,from,next){

          let isFound = false;

          let user = Vue.$cookies.get('meri_web_shop.current_user');

          user.roles.forEach(role => {
            if(role == "manage expense"){
              isFound = true
            }
          });

          if(isFound){

            next();

          }else{

            next(from.fullPath);
            
            Element.Message({
              type: 'error',
              showClose: true,
              message: "You don't have permission to access this module.",
            });

          }

        }

      },
      {
        path: 'expenses-purchases/bill',
        name: 'Bills',
        component: () => import('../views/dashboard/expensesPurchases/bills/index.vue'),
        beforeEnter(to,from,next){

          let isFound = false;

          let user = Vue.$cookies.get('meri_web_shop.current_user');

          user.roles.forEach(role => {
            if(role == "manage expense"){
              isFound = true
            }
          });

          if(isFound){

            next();
            
          }else{

            next(from.fullPath);
            
            Element.Message({
              type: 'error',
              showClose: true,
              message: "You don't have permission to access this module.",
            });

          }

        }

      },
      {
        path: 'expenses-purchases/bill/add',
        name: 'Add Bill',
        component: () => import('../views/dashboard/expensesPurchases/bills/addBill.vue'),
        beforeEnter(to,from,next){

          let isFound = false;

          let user = Vue.$cookies.get('meri_web_shop.current_user');

          user.roles.forEach(role => {
            if(role == "manage expense"){
              isFound = true
            }
          });

          if(isFound){

            next();
            
          }else{

            next(from.fullPath);
            
            Element.Message({
              type: 'error',
              showClose: true,
              message: "You don't have permission to access this module.",
            });

          }

        }

      },
      {
        path: 'expenses-purchases/bill/edit/:id',
        name: 'Edit Bill',
        component: () => import('../views/dashboard/expensesPurchases/bills/editBill.vue'),
        props: true,
        beforeEnter(to,from,next){

          let isFound = false;

          let user = Vue.$cookies.get('meri_web_shop.current_user');

          user.roles.forEach(role => {
            if(role == "manage expense"){
              isFound = true
            }
          });

          if(isFound){

            next();

          }else{

            next(from.fullPath);
            
            Element.Message({
              type: 'error',
              showClose: true,
              message: "You don't have permission to access this module.",
            });

          }

        }

      },
      //expenses/purchases routes end

      //notifications routes start
      {
        path: 'notification',
        name: 'History',
        component: () => import('../views/notifications/index.vue'),
      },
      //notifications routes start

      //chat support routes start
      {
        path: 'support-member',
        name: 'Support',
        component: () => import('../views/dashboard/chatSupport/index.vue'),
      },
      //chat support routes end

      //user management routes start
      {
        path: 'user-management',
        name: 'User Management',
        component: () => import('../views/dashboard/userManagement/index.vue'),
        beforeEnter(to,from,next){

          let isFound = false;

          let user = Vue.$cookies.get('meri_web_shop.current_user');

          user.roles.forEach(role => {
            if(role == "manage admins"){
              isFound = true
            }
          });

          if(isFound){

            next();

          }else{

            next(from.fullPath);
            
            Element.Message({
              type: 'error',
              showClose: true,
              message: "You don't have permission to access this module.",
            });

          }

        }

      },
      //user management routes end

      //CRM routes start
      {
        path: 'crm/contact',
        name: 'Contacts',
        component: () => import('../views/dashboard/CRM/contacts/index.vue'),
        beforeEnter(to,from,next){

          let isFound = false;

          let user = Vue.$cookies.get('meri_web_shop.current_user');

          user.roles.forEach(role => {
            if(role == "manage crm"){
              isFound = true
            }
          });

          if(isFound){

            next();

          }else{

            next(from.fullPath);
            
            Element.Message({
              type: 'error',
              showClose: true,
              message: "You don't have permission to access this module.",
            });

          }

        }

      },
      {
        path: 'crm/group',
        name: 'Groups',
        component: () => import('../views/dashboard/CRM/groups/index.vue'),
        beforeEnter(to,from,next){

          let isFound = false;

          let user = Vue.$cookies.get('meri_web_shop.current_user');

          user.roles.forEach(role => {
            if(role == "manage crm"){
              isFound = true
            }
          });

          if(isFound){

            next();

          }else{

            next(from.fullPath);
            
            Element.Message({
              type: 'error',
              showClose: true,
              message: "You don't have permission to access this module.",
            });

          }

        }

      },
      {
        path: 'crm/email',
        name: 'Emails',
        component: () => import('../views/dashboard/CRM/emails/index.vue'),
        beforeEnter(to,from,next){

          let isFound = false;

          let user = Vue.$cookies.get('meri_web_shop.current_user');

          user.roles.forEach(role => {
            if(role == "manage crm"){
              isFound = true
            }
          });

          if(isFound){

            next();

          }else{

            next(from.fullPath);
            
            Element.Message({
              type: 'error',
              showClose: true,
              message: "You don't have permission to access this module.",
            });

          }

        }

      },
      {
        path: 'crm/smtp',
        name: 'SMTPs',
        component: () => import('../views/dashboard/CRM/smtps/index.vue'),
        beforeEnter(to,from,next){

          let isFound = false;

          let user = Vue.$cookies.get('meri_web_shop.current_user');

          user.roles.forEach(role => {
            if(role == "manage crm"){
              isFound = true
            }
          });

          if(isFound){

            next();

          }else{

            next(from.fullPath);
            
            Element.Message({
              type: 'error',
              showClose: true,
              message: "You don't have permission to access this module.",
            });

          }

        }

      },
      //CRM routes end

      {
        path: 'help',
        name: 'help',
        component: () => import('../views/dashboard/help/index.vue')
      },
      {
        path: 'settings/profile',
        name: 'Profile',
        component: () => import('../views/settings/profile.vue'),
      },
      {
        path: 'reset-password',
        name: 'ResetPassword',
        component: () => import('../views/login/reset-password.vue'),
      },

      // {
      //   path: 'my-template',
      //   name: 'myTemplate',
      //   component: () => import('../views/dashboard/myTemplate.vue')
      // },
      // {
      //   path: 'change-template',
      //   name: 'changeTemplate',
      //   component: () => import('../views/dashboard/changeTemplate.vue')
      // },

      {
        path: '*',
        component: () => import('../views/dashboard/error.vue')
      }
    ]
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to,from,next) => {

  // hide sidebar on route change in case of screens with width less than 1024
  if(window.innerWidth <= 1024){

    document.body.classList.remove('activeIpad');
    document.body.classList.add('hideSideBar');

  }

  let user = Vue.$cookies.get('meri_web_shop.current_user');

  if((to.fullPath.includes('/onboarding')) && user){

    next(from.fullPath);
    
  }else if(to.fullPath.includes('/login') && user){

    next(from.fullPath);

  }else if((to.fullPath.includes('/forgotPassword')) && user){

    next(from.fullPath);

  }else if((to.fullPath.includes('/forgotPassword/reset')) && user){

    next(from.fullPath);

  }else if((to.fullPath.includes('/unsubscribe')) && user){

    next(from.fullPath);

  }else if(!user){

    if(!to.fullPath.includes('login') && to.name != 'onboarding' && to.name != 'onboarding-ai' && (!to.fullPath.includes('/forgotPassword')) &&
    (!to.fullPath.includes('/forgotPassword/reset')) && (!to.fullPath.includes('/email-verification')) &&
    (!to.fullPath.includes('/unsubscribe')) && !to.query.token ){

      Element.Message({
        type: 'error',
        showClose: true,
        message: "Session Expired",
      });

      next('/login')

      store.commit('accounts_module/CLEAR_MODULE');
      store.commit('bills_module/CLEAR_MODULE');
      store.commit('blogCategories_module/CLEAR_MODULE');
      store.commit('blogs_module/CLEAR_MODULE');
      store.commit('content_blocks/CLEAR_MODULE');
      store.commit('coupons_module/CLEAR_MODULE');
      store.commit('customers_module/CLEAR_MODULE');
      store.commit('expenses_module/CLEAR_MODULE');
      store.commit('bar_graph_module/CLEAR_MODULE');
      store.commit('pie_graph_module/CLEAR_MODULE');
      store.commit('line_graph_module/CLEAR_MODULE');
      store.commit('home_banner_module/CLEAR_MODULE');
      store.commit('gallery_module/CLEAR_MODULE');
      store.commit('notifications_module/CLEAR_MODULE');
      store.commit('orders_module/CLEAR_MODULE');
      store.commit('productCategories_module/CLEAR_MODULE');
      store.commit('products_module/CLEAR_MODULE');
      store.commit('redirect/CLEAR_MODULE');
      store.commit('robot/CLEAR_MODULE');
      store.commit('seo_config/CLEAR_MODULE');
      store.commit('settings_module/CLEAR_MODULE');
      store.commit('shipping_module/CLEAR_MODULE');
      store.commit('dashboard_stats/CLEAR_MODULE');
      store.commit('order_stats/CLEAR_MODULE');
      store.commit('product_stats/CLEAR_MODULE');
      store.commit('store_categories_module/CLEAR_MODULE');
      store.commit('themes_module/CLEAR_MODULE');
      store.commit('subscription_module/CLEAR_MODULE');
      store.commit('vendor_module/CLEAR_MODULE');
      store.commit('page_details_module/CLEAR_MODULE');

    }else{

      next();

    }

  }else{

    next();

  }
  
});


// router.beforeEach((to, from, next) => {
//   if (
//     to.matched.some(record => record.meta.requiresAuth) &&
//     (!store.state.loggedIn)
//   ) {
//     // this route requires auth, check if logged in
//     // if not, redirect to login page.
//     next({
//       path: '/login',
//       query: { redirect: to.fullPath }
//     })
//   } else {
//     next()
//   }
// })


export default router
