import axios from '../../plugins/axios';
export const coupons_module = {
    state: ()=>({
        pending: false,
        data: {
            current_page: 1,
            data: [],
            last_page: null,
            searchedKeyword:'',
        },
        message: null,
        success: null,
        error: null
    }),
    mutations: {
        fetch_coupons_request(state){
            state.pending = true;
        },
        fetch_coupons_success(state, payload){

            if(payload.loadData == 'new'){
                state.data.data = payload.coupons.data
            }
            if(payload.loadData == 'more'){

                let moreCustomers = payload.coupons.data

                moreCustomers.forEach(element => {
                    state.data.data.push(element);
                });

            }

            state.data.current_page = payload.coupons.current_page
            state.data.last_page = payload.coupons.last_page
            state.pending = false;
            state.success = true;
            state.error = null;
            state.message = payload.message;

        },
        fetch_coupons_failure(state, payload){
            state.data = payload;
            state.pending = false;
            state.success = false;
            state.error = payload.error;
            state.message = payload.message
        },
        add_coupons_list(state,payload){
            state.data.data.unshift(payload);
        },
        update_coupons_list(state,payload){

            const index = state.data.data.findIndex(x=>x.id === payload.id)
  
            if(index !== -1){
                state.data.data[index] = payload;
            }
        },
        delete_coupons_list(state,payload){

            state.data.data.splice(payload,1);

        },
        coupons_bulk_action(state,payload){

            if(payload.action == 'delete'){

                state.data.data = state.data.data.filter((coupon)=>{

                    if(!payload.listIds.includes(coupon.id)){
                        
                        return coupon

                    }

                });

            }

        },
        update_searched_keyword(state,payload){
            state.data.searchedKeyword = payload.keyword
        },
        remove_searched_keyword(state){
            state.data.searchedKeyword = ''
        },
        CLEAR_MODULE(state){
            state.pending = false
            state.message = null
            state.success = null
            state.error = null

            state.data.current_page = 1
            state.data.data = []
            state.data.last_page = null,
            state.data.searchedKeyword= ''
        }
    },
    actions:{
        async fetchStoreCouponsRequest({commit},{size, page, loadData}){

            commit('fetch_coupons_request');
            try{

                let res = null

                if(this.state.coupons_module.data.searchedKeyword.length > 0){
                    
                    res = await axios.get('/getCoupons',{
                        params:{
                            search: this.state.coupons_module.data.searchedKeyword,
                            offset: size,
                            page:page
                        }
                    })
                
                }else{
                    res = await axios.get('/getCoupons',{
                        params:{
                            offset: size,
                            page:page
                        }
                    })
                }

                if(res.data.status_code == '1070'){
                    res.data.loadData = loadData

                    commit('fetch_coupons_success', res.data)
                }

            }catch(ex){
                commit('fetch_coupons_failure', {error: ex, message: ex})
            }

        }

    },
    getters: {
        coupons(state){
            return state.data.data
        },
        isPending(state){
            return state.pending
        },
        currentPage(state){
            return state.data.current_page
        },
        lastPage(state){
            return state.data.last_page
        },
        keyword(state){
            return state.data.searchedKeyword
        },
        message(state){
            return state.message
        },
        error:(state)=>state.error
    }
}