import axios from '../../plugins/axios';
export const admin_users_module = {
    state: ()=>({
        pending: false,
        data: {
            current_page: 1,
            data: [],
            last_page: null,
            searchedKeyword:'',
            roles:[],
        },
        message: null,
        success: null,
        error: null
    }),
    mutations: {
        fetch_admin_users_request(state){
            state.pending = true;
        },
        fetch_admin_users_success(state, payload){

            if(payload.loadData == 'new'){

                state.data.data = payload.admins.data.map(admin=>{

                    admin.isActive = admin.isActive ? true : false;

                    return admin;
                })

            }
            if(payload.loadData == 'more'){

                let moreCustomers = payload.admins.data.map(admin=>{

                    admin.isActive = admin.isActive ? true : false;

                    return admin;
                })
    
                moreCustomers.forEach(element => {
                    state.data.data.push(element);
                });

            }

            state.data.current_page = payload.admins.current_page;
            state.data.last_page = payload.admins.last_page;
            state.pending = false;
            state.success = true;
            state.error = null;
            state.message = payload.message;

        },
        fetch_admin_users_failure(state, payload){

            state.data = payload;
            state.pending = false;
            state.success = false;
            state.error = payload.error;
            state.message = payload.message;

        },
        fetch_admin_roles_success(state, payload){

            payload.roles.forEach(role=>{
                let obj = {
                    name: role.name
                }
    
               state.data.roles.push(obj)
            })

            state.pending = false;
            state.success = true;
            state.error = null;
            state.message = payload.message;

        },
        add_admin_users_list(state,payload){

            state.data.data.unshift(payload);

        },
        update_admin_users_list(state,payload){

            state.data.data[payload.index] = payload.updatedUser;

        },
        update_admin_user_status(state,payload){

            state.data.data[payload.index].isActive = payload.updatedUser.isActive;

        },
        delete_admin_users_list(state,payload){

            state.data.data.splice(payload,1);

        },
        admin_users_bulk_action(state,payload){

            if(payload.action == 'statusChange'){

                state.data.data = state.data.data.filter((admin)=>{

                    if(payload.listIds.includes(admin.id)){

                        admin.isActive = payload.status;

                        return admin;

                    }else{

                        return admin;

                    }

                });
            }

        },
        update_searched_keyword(state,payload){ 

            state.data.searchedKeyword = payload.keyword;


        },
        remove_searched_keyword(state){

            state.data.searchedKeyword = ''

        },

        CLEAR_MODULE(state){

            state.pending = false;
            state.message = null;
            state.success = null;
            state.error = null;

            state.data.current_page = 1;
            state.data.data = [];
            state.data.last_page = null;
            state.data.searchedKeyword= '';

        }
    },
    actions:{
        async fetchStoreAdminUsersRequest({commit},{size, page, loadData}){

            commit('fetch_admin_users_request');
            try{

                let res = null;
                if(this.state.admin_users_module.data.searchedKeyword.length > 0){

                    res = await axios.get('/admin/all',{
                        params:{
                            search: this.state.admin_users_module.data.searchedKeyword,
                            offset: size,
                            page: page
                        }
                    });

                }else{

                    res = await axios.get('/admin/all',{
                        params:{
                            offset: size,
                            page: page
                        }
                    });

                }
                if(res.data.status_code == '1900'){

                    res.data.loadData = loadData;

                    commit('fetch_admin_users_success', res.data);

                }
            }catch(ex){

                commit('fetch_admin_users_failure', {error: ex, message: ex});

            }
        },
        async fetchAdminRolesRequest({commit}){

            commit('fetch_admin_users_request');
            try{

                let res = await axios.get('/roles/all');
                if(res.data.status_code == "3000"){

                    commit('fetch_admin_roles_success', res.data);

                }
            }catch(ex){

                commit('fetch_admin_users_failure', {error: ex, message: ex});

            }

        }
    },
    getters: {
        admins(state){
            return state.data.data
        },
        keyword(state){
            return state.data.searchedKeyword
        },
        roles(state){
            return state.data.roles
        },
        isPending(state){
            return state.pending
        },
        currentPage(state){
            return state.data.current_page
        },
        lastPage(state){
            return state.data.last_page
        },
        message(state){
            return state.message
        },
        error:(state)=>state.error
    }
}