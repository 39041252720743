import axios from '../../plugins/axios';
export const support_members_module = {
    state: ()=>({
        pending: false,
        data: {
            current_page: 1,
            data: [],
            last_page: null,
            searchedKeyword:'',
        },
        message: null,
        success: null,
        error: null
    }),
    mutations: {
        fetch_support_members_request(state){

            state.pending = true;

        },
        fetch_support_members_success(state, payload){

            if( payload.loadData == 'new'){
                state.data.data = payload.support.data
            }
            if(payload.loadData == 'more'){
                let moreCustomers = payload.support.data

                moreCustomers.forEach(element => {
                    state.data.data.push(element);
                });
            }

            state.data.current_page = payload.support.current_page
            state.data.last_page = payload.support.last_page
            state.pending = false;
            state.success = true;
            state.error = null;
            state.message = payload.message;

        },
        fetch_support_members_failure(state, payload){

            state.data = payload;
            state.pending = false;
            state.success = false;
            state.error = payload.error;
            state.message = payload.message

        },
        add_support_member_list(state,payload){

            state.data.data.unshift(payload);

        },
        update_support_member_list(state,payload){

            state.data.data[payload.index] = payload.updatedMember

        },
        update_support_member_status(state,payload){

            state.data.data[payload.index].isActive = payload.updatedCustomer.isActive

        },
        delete_support_member_list(state,payload){

            state.data.data.splice(payload,1);

        },
        support_member_bulk_action(state,payload){

            if(payload.action == 'delete'){
            
                state.data.data = state.data.data.filter((member)=>{

                    if(!payload.listIds.includes(member.id)){

                        return member;

                    }

                });

            }

        },
        update_searched_keyword(state,payload){ 

            state.data.searchedKeyword = payload.keyword

        },
        remove_searched_keyword(state){

            state.data.searchedKeyword = ''
            
        },
        CLEAR_MODULE(state){

            state.pending = false
            state.message = null
            state.success = null
            state.error = null

            state.data.current_page = 1
            state.data.data = []
            state.data.last_page = null,
            state.data.searchedKeyword= ''

        }
    },
    actions:{
        async fetchStoreSupportMembersRequest({commit},{size, page, loadData}){

            commit('fetch_support_members_request');
            try{
                let res = null

                if(this.state.support_members_module.data.searchedKeyword.length > 0){

                    res = await axios.get('/chat-support/all',{
                        params:{
                            search: this.state.support_members_module.data.searchedKeyword,
                            offset: size,
                            page: page
                        }
                    })

                }else{

                    res = await axios.get('/chat-support/all',{
                        params:{
                            offset: size,
                            page: page
                        }
                    })

                }
                if(res.data.status_code == '1270'){

                    res.data.loadData = loadData

                    commit('fetch_support_members_success', res.data)

                }

            }catch(ex){
                commit('fetch_support_members_failure', {error: ex, message: ex})
            }

        }

    },
    getters: {
        members(state){
            return state.data.data
        },
        keyword(state){
            return state.data.searchedKeyword
        },
        isPending(state){
            return state.pending
        },
        currentPage(state){
            return state.data.current_page
        },
        lastPage(state){
            return state.data.last_page
        },
        message(state){
            return state.message
        },
        error:(state)=>state.error
    }
}